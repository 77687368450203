import { useEffect, useState } from "react";


//import from prime react
import InputCalendar from "../InputCalendar/InputCalendar";


//Dropdown options
const PlaceOptions = [
  { key: "Físico", name: "Físico" },
  { key: "Zoom", name: "Zoom" },
]
const FocusOptions = [
  { key: "Vida", name: "Vida" },
  { key: "GMM", name: "GMM" },
  { key: "Auto", name: "Auto" },
  { key: "Hogar", name: "Hogar" },
  { key: "Mascota", name: "Mascota" },
  { key: "Empresarial", name: "Empresarial" },
]
const ProductOptions = [
  { key: 1, name: "Dotal menores" }, 
  { key: "Trasciende", name: "Trasciende" }, 
  { key: 2, name: "Trasciende menores" }, 
  { key: 3, name: "Hombre clave protección" }, 
  { key: 4, name: "Hombre clave ahorro" }, 
  { key: "Privilegio", name: "Privilegio"},
  { key: "Platino", name: "Platino"},
  { key: "Dotal", name: "Dotal"},
  { key: "Profesional", name: "Profesional"},
  { key: "profesional Abuelos", name: "profesional Abuelos"},
  { key: "Proyecta", name: "Proyecta"},
  { key: "Proyecta Afecto", name: "Proyecta Afecto"},
  { key: "Consolida", name: "Consolida"},
  { key: "Consolida PPR", name: "Consolida PPR"},
  { key: "Visión Plus", name: "Visión Plus"},
  { key: "Ordinario de vida", name: "Ordinario de vida"},
  { key: "Elige", name: "Elige"},
  { key: "Auto", name: "Auto"},
  { key: "Hogar Versátil", name: "Hogar Versátil"},
  { key: "Mi Mascota", name: "Mi Mascota"},
  { key: 5, name: "GMM Versátil" }, // GMM
  { key: 6, name: "GMM Premium" }, // GMM
  { key: 7, name: "GMM Platino" }, // GMM
  { key: 8, name: "GMM Personaliza" }, // GMM
  { key: 9, name: "GMM Conexión" }, // GMM
  { key: 10, name: "GMM Flexibles - Ámbar" }, // GMM
  { key: 11, name: "GMM Flexibles - Cuarzo" }, // GMM
  { key: 12, name: "GMM Flexibles - Índigo" }, // GMM
];

const EstatusOptions = [
  { key: "Perdido", name: "Perdido" },
  { key: "Prospecto", name: "Prospecto" },
  { key: "Cita", name: "Cita" },
  { key: "Negociación", name: "Negociación" },
  { key: "Casi cierre", name: "Casi cierre" },
  { key: "Solicitud", name: "Solicitud" },
  { key: "Pagado", name: "Pagado" },
]


export default function ({ row, index, handleChangeRow }) {
  const [initRender, setInitRender] = useState(1)

  // ESTATUS
  const [estatusDelRegistro, setEstatusDelRegistro] = useState(EstatusOptions.find(option => option.name === row.estatus)?.name || "")
  //PRIMERA FECHA
  const [editingPrimeraFecha, setEditingPrimeraFecha] = useState(false);
  const [PrimeraFechaValue, setPrimeraFechaValue] = useState(stringToFecha(row.fecha) ?? '');
  //LUGAR
  const [lugar, setLugar] = useState(PlaceOptions.find(option => option.name === row.lugar)?.name || "")
  //NOMBRE CLIENTE
  const [editingNombreCliente, setEditingNombreCliente] = useState(false);
  const [NombreClienteValue, setNombreClienteValue] = useState(row.nombre_cliente);
  //TELEFONO
  const [editingTelefono, setEditingTelefono] = useState(false);
  const [TelefonoValue, setTelefonoValue] = useState(row.telefono_cliente);


  const [editingPresupuesto, setEditingPresupuesto] = useState(false);
  const [presupuestoValue, setPresupuestoValue] = useState(row.presupuesto);

  const [editingSegundaFecha, setEditingSegundaFecha] = useState(false);
  const [SegundaFechaValue, setSegundaFechaValue] = useState(stringToFecha(row.fecha_segunda_cita) ?? '');

  const [editingComentarios, setEditingComentarios] = useState(false);
  const [comentariosValue, setComentariosValue] = useState(row.comentarios);


  //default states
  const [enfoque, setEnfoque] = useState(FocusOptions.find(option => option.name === row.enfoque)?.name || "")
  const [producto, setProducto] = useState(ProductOptions.find(option => option.name === row.producto)?.name || "")


  // FECHA
  function stringToFecha(fechaString) {
    try {
      if (fechaString) {
        const [dia, mes, año] = fechaString.split('/').map(part => parseInt(part, 10));
        return new Date(año, mes - 1, dia);
      }
    } catch {
      return ''
    }
  }
  function fechaToString(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fechaFormateada = fechaObj.toLocaleDateString('es-ES', opciones);
    return fechaFormateada;
  }
  const handlePrimeraFechaChange = (e) => {
    setPrimeraFechaValue(e.value)
    handleChangeRow(row.uuid, 'fecha', fechaToString(e.value), setEditingPrimeraFecha);
  };

  //NOMBRE CLIENTE
  const handleNombreClienteChange = (e) => {
    setNombreClienteValue(e.target.value);
  };
  const handleNombreClienteBlur = () => {
    setEditingNombreCliente(false);
    handleChangeRow(row.uuid, 'nombre_cliente', NombreClienteValue);
  };

  //TELEFONO
  const handleTelefonoChange = (e) => {
    setTelefonoValue((v) => e.target.validity.valid ? e.target.value : v);
  };
  const handleTelefonoBlur = () => {
    setEditingTelefono(false);
    handleChangeRow(row.uuid, 'telefono_cliente', TelefonoValue);
  };


  const handleComentariosChange = (e) => {
    setComentariosValue(e.target.value);
  };

  const handleComentariosBlur = () => {
    setEditingComentarios(false);
    handleChangeRow(row.uuid, 'comentarios', comentariosValue);
  };


  const handlePresupuestoChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9.]/g, "");
    setPresupuestoValue(rawValue);
  };

  const handlePresupuestoBlur = () => {
    setEditingPresupuesto(false);
    handleChangeRow(row.uuid, 'presupuesto', presupuestoValue);
  };



  const handleSegundaFechaChange = (e) => {
    setSegundaFechaValue(e.value)
    handleChangeRow(row.uuid, 'fecha_segunda_cita', fechaToString(e.value), setEditingSegundaFecha);
  };

  const handleChangeSelect = (e, state) => {

    let value = e.target.value;


    if (state == 'estatus') {
      setEstatusDelRegistro(value)
    }
    else if (state == 'lugar') {
      setLugar(value)

    }
    else if (state == 'enfoque') {
      setEnfoque(value)

    }
    else if (state == 'producto') {
      setProducto(value)

    }

    handleChangeRow(row.uuid, state, value)

    //TODO
    //aqui vamos a agregar el uuid de la fila, junto el parametro que vamos a modificar y su valor
    //y no solamente en los selects cesar, si no en cualquier cambio que se le realice a esta fila okay? eres un crack hermano

  }

  const formatCurrency = (value) => {
    if (!value) return ''; // Si el valor es nulo o indefinido, devuelve una cadena vacía
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(Number(value));
  };









  useEffect(() => {
    if (initRender == 2) {
      // handleChangeRow(row, estatusDelRegistro)
    } else {
      setInitRender(2)
    }
  }, [])



  return (
    <tr>
      <td>
        <select className="estatusSelect" value={estatusDelRegistro} onChange={e => handleChangeSelect(e, 'estatus')}>
          {EstatusOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>


      <td onClick={() => setEditingPrimeraFecha(true)}>
        {
          editingPrimeraFecha ?
            <InputCalendar
              value={PrimeraFechaValue}
              onChange={handlePrimeraFechaChange}
            />

            :
            PrimeraFechaValue ? fechaToString(PrimeraFechaValue) : 'Sin datos'
        }
      </td>


      <td>
        <select value={lugar} onChange={e => handleChangeSelect(e, 'lugar')}>
          {PlaceOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>

      {/* NOMBRE */}
      <td onClick={() => setEditingNombreCliente(true)}>
        {editingNombreCliente ? (
          <input
            type="text"
            value={NombreClienteValue}
            onChange={handleNombreClienteChange}
            onBlur={handleNombreClienteBlur}
            autoFocus
          />
        ) : (
          NombreClienteValue ? NombreClienteValue : 'Sin datos'
        )}
      </td>

      {/* TELEFONO */}
      <td onClick={() => setEditingTelefono(true)}>
        {editingTelefono ? (
          <input
            type="number"
            pattern="[+]?[0-9]*"
            value={TelefonoValue}
            onChange={handleTelefonoChange}
            onBlur={handleTelefonoBlur}
            autoFocus
          />
        ) : (
          TelefonoValue ? TelefonoValue : 'Sin datos'
        )}
      </td>


      <td onClick={() => setEditingSegundaFecha(true)}>
        {
          editingSegundaFecha ?
            <InputCalendar
              value={SegundaFechaValue}
              onChange={handleSegundaFechaChange}
            />

            :
            SegundaFechaValue ? fechaToString(SegundaFechaValue) : 'Sin datos'
        }
      </td>

      <td onClick={() => setEditingPresupuesto(true)}>
        {editingPresupuesto ? (
          <input
            type="text"
            value={presupuestoValue}
            onChange={handlePresupuestoChange}
            onBlur={handlePresupuestoBlur}
            autoFocus
          />
        ) : (
          presupuestoValue ? formatCurrency(presupuestoValue) : 'Sin datos'
        )}
      </td>



      <td>
        <select value={producto} onChange={e => handleChangeSelect(e, 'producto')}>
          <option value="">Sin Datos</option>
          {ProductOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>


      <td>
        <select value={enfoque} onChange={e => handleChangeSelect(e, 'enfoque')}>
          <option value="">Sin Datos</option>
          {FocusOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>




      <td onClick={() => setEditingComentarios(true)}>
        {editingComentarios ? (
          <textarea
            type="text"
            rows={4}
            cols={100}
            value={comentariosValue}
            onChange={handleComentariosChange}
            onBlur={handleComentariosBlur}
            autoFocus
            style={{ resize: "none" }}
          />
        ) : (
          comentariosValue ? comentariosValue : 'Sin comentarios'
        )}
      </td>


    </tr>
  )
}