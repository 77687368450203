import React from 'react';



import './RegistrosTable.scss';


// db connection
import SteinStore from "stein-js-client";

// pop up library
import Swal from 'sweetalert2';
import Row from './Row';



const CustomRegistrosTable = ({ data, small = false, setConsultarRegistros }) => {
  const RegistrosAPI = new SteinStore(process.env.REACT_APP_REGISTROS_API);


  
  
  
  
  const handleChangeRow = (uuid, state, value, setEditingSegundaFecha) => {

    
    //Aquí tenemos que hacer la selección del estado
    Swal.fire({
      title: 'Actualizando el estado...',
      text: 'Por favor espere un momento',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCloseButton: false,
      didOpen: () => {
          Swal.showLoading();
        }
      })

      
      
      
      RegistrosAPI
      .edit("Registros", {
        search: { uuid: uuid },
        set: { [state]: value,  }, //Aquí colocamos el nuevo estado de la cita, por defecto vienen como citas
        authentication: {
          username: process.env.REACT_APP_REGISTROS_USERNAME,
          password: process.env.REACT_APP_REGISTROS_PASSWORD
        }
      })
      .then(res => {
        
    
        //actualizar tabla, para que se vea la cita que acabas de modificar
        setConsultarRegistros(1)
        setEditingSegundaFecha && setEditingSegundaFecha(false) // esto es unicamente cuando editemos la segunda fecha de una fila

    
    
      })
      .catch(e => {
        setEditingSegundaFecha && setEditingSegundaFecha(false) // esto es unicamente cuando editemos la segunda fecha de una fila
        console.error("Error actualizando registro", e)
        Swal.fire({
          title: 'Error, recarga la página',
          text: 'Por favor espere un momento',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          showCloseButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        })
      })
      
    }





  return (
    <div className={`registros-table-container ${small ? "small" : ""}`}>
      <table className="registros-table">
        <thead>
          <tr>
            <th>Estatus</th>
            <th>Fecha 1ra Cita</th>
            <th>Lugar</th>
            <th>Nombre Cliente</th>
            <th>Telefono Cliente</th>
            <th>Fecha 2da Cita</th>
            <th>Presupuesto</th>
            <th>Producto</th>
            <th>Enfoque</th>
            <th>Comentarios</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <Row 
              key={index} 
              row={row} 
              index={index} 
              handleChangeRow={handleChangeRow} 
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomRegistrosTable;
