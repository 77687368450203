import { Calendar } from 'primereact/calendar';

import "./InputCalendar.scss"

const InputCalendar = ({value, onChange, onBlur }) => {
    
    return(<div className='inputCalendar'>
        <Calendar onBlur={onBlur} autoFocus value={value} onChange={onChange} dateFormat="dd/mm/yy" />
    </div>)
}

export default InputCalendar