import { useEffect, useState } from "react";

// Components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import Dropdown from "../../Components/Dropdown/Dropdown";

// pop up library
import Swal from 'sweetalert2';

// db connection
import SteinStore from "stein-js-client";

//Styles
import "./GestionarCitas.scss"
import CustomRegistrosTable from "../../Components/RegistrosTable/RegistrosTable";



export default function ({
  title,
  cua,
  asesorData,
}) {
  //importamos Stein para la base de datos
  const RegistrosAPI = new SteinStore(process.env.REACT_APP_REGISTROS_API);
  const [citasDB, setCitasDB] = useState([]) // 1




  //condicionales para funcionamiento de enviar formulario
  const [consultarRegistros, setConsultarRegistros] = useState(1) // 1
  const [enviarCambios, setEnviarFormulario] = useState(false) // 1





  // función de enviar formulario a base de datos
  const handleRefrescarTabla = (e) => {
    setConsultarRegistros(1)
  }








  //useEffects
  useEffect(() => {
    if (consultarRegistros) {


      setConsultarRegistros(0)
      const loadingSwal = Swal.fire({
        title: 'Cargando registro...',
        text: 'Por favor espere un momento',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })






      //llamar api de registros y filtrar los que me importan
      RegistrosAPI // Consultamos DB donde tenemos todos los asesores
        .read(
          "Registros",
          {
            authentication:
            {
              username: process.env.REACT_APP_REGISTROS_USERNAME,
              password: process.env.REACT_APP_REGISTROS_PASSWORD
            }
          })





        .then(data => {
          const filteredData = data.filter(registro => registro.uuid_asesor == asesorData.asesorUuid)
          if (filteredData && filteredData.length > 0) {
            filteredData.reverse();
          }   
          setCitasDB(filteredData)
          loadingSwal.close()

        })






        .catch(e => {
          console.error(e);
          Swal.fire({
            title: 'Error, recarga la página',
            text: 'Por favor espere un momento',
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCloseButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          })
        })
    }
  }, [consultarRegistros])








  return (<>
    <AppPageTemplate title={title} className={"GestionarCitas"}>
      {/* <div style={{height: "10px"}} /> */}








      {/* Componente de tabla con sus acciones de actualizar */}
      {/* Lo sacaremos de PrimeReact */}

      {
        (Array.isArray(citasDB) && citasDB?.length) ?
          <CustomRegistrosTable
            data={citasDB}
            setConsultarRegistros={setConsultarRegistros}
          />
          :
          <h2 className="h2 error">Tu usuario no tiene ningún registro</h2>
      }





       <a className="aLink" href="https://registros.asesoresprocapita.com/" target="_blank" >Registrar nuevo prospecto</a>



      <div style={{ height: "30px" }} />
    </AppPageTemplate>
  </>);
}